<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>企业用户管理</p>
			<i>/</i>
			<p @click="jumpPage">企业用户列表</p>
			<i>/</i>
			<p class="active">认证信息</p>
		</nav>
		
		<div class="page_edit_form" @click="clickPageBankOn">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="" prop="useful_life">
					<p class="form_label_p">认证资质类型</p>
					<el-radio-group v-model="ruleForm.type">
						<el-radio label="1">企业</el-radio>
						<el-radio label="2">个体工商户</el-radio>
						<el-radio label="3">个人微小商户</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="" prop="company" v-if="ruleForm.type < 3">
					<p class="form_label_p">企业或个体商户名称</p>
					<el-input class="input_long" v-model="ruleForm.company" placeholder="请输入企业或个体商户名称">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="cust_another_name" v-if="ruleForm.type < 3">
					<p class="form_label_p">商户简称</p>
					<el-input class="input_long" v-model="ruleForm.cust_another_name" placeholder="请输入商户简称">
					</el-input>
				</el-form-item>
				<el-form-item prop="company_area" v-if="ruleForm.type < 3">
					<p class="form_label_p">商户地址</p>
					<div class="address">
						<el-select v-model="ruleForm.company_province" filterable placeholder="省"
							class="miniInput" @change="companyProvinceFn">
							<el-option v-for="item in provinceData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="companyCityShow">
						<el-select v-model="ruleForm.company_city" filterable placeholder="市" class="miniInput"
							@change="companyCityFn">
							<el-option v-for="item in companyCityData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="companyAreaShow">
						<el-select v-model="ruleForm.company_area" filterable placeholder="区" class="miniInput">
							<el-option v-for="item in companyAreaData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item label="" prop="company_address" v-if="ruleForm.type < 3">
					<p class="form_label_p">商户详细地址</p>
					<el-input class="input_long" v-model="ruleForm.company_address" placeholder="请输入商户详细地址">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="legal_name">
					<p class="form_label_p">法人/个人姓名</p>
					<el-input class="input_long" v-model="ruleForm.legal_name" placeholder="请输入法人/个人姓名">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="legal_phone">
					<p class="form_label_p">法人/个人联系方式</p>
					<el-input class="input_long" v-model="ruleForm.legal_phone" maxlength="11"
						placeholder="输入联系方式">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="legal_id_number">
					<p class="form_label_p">身份证号</p>
					<el-input class="input_long" v-model="ruleForm.legal_id_number" placeholder="请输入身份证号">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="certifi_effect">
					<p class="form_label_p">法人证件生效期</p>
					<el-date-picker v-model="ruleForm.certifi_effect" value-format="yyyy-MM-dd" type="date"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="" prop="legal_id_valid_long">
					<p class="form_label_p">身份证是否长期有效</p>
					<el-radio-group v-model="ruleForm.legal_id_valid_long">
						<el-radio label="0">否</el-radio>
						<el-radio label="1">是</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="" prop="legal_id_valid" v-if="ruleForm.legal_id_valid_long == '0'">
					<p class="form_label_p">法人身份证有效期</p>
					<el-date-picker v-model="ruleForm.legal_id_valid" value-format="yyyy-MM-dd" type="date"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<div class="form_img_up">
					<p class="title">身份证照片</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(1)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 1)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="SFZfileList">
								<div class="img_up_style">
									<p class="p3">身份证正面上传</p>
								</div>
							</el-upload>
						</li>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(2)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 2)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="SFFfileList">
								<div class="img_up_style">
									<p class="p3">身份证反面上传</p>
								</div>
							</el-upload>
						</li>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(3)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 3)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="SCfileList">
								<div class="img_up_style">
									<p class="p3">手持身份证上传</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<div class="form_img_up" v-if="ruleForm.type < 3">
					<p class="title">营业执照</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(4)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 4)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="YYZZfileList">
								<div class="img_up_style">
									<p class="p3">营业执照上传</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<el-form-item label="" prop="bs_license_effect" v-if="ruleForm.type < 3">
					<p class="form_label_p">企业证件生效期</p>
					<el-date-picker v-model="ruleForm.bs_license_effect" value-format="yyyy-MM-dd" type="date"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="" prop="useful_life" v-if="ruleForm.type < 3">
					<p class="form_label_p">营业执照是否长期有效</p>
					<el-radio-group v-model="ruleForm.validtime_long">
						<el-radio label="0">否</el-radio>
						<el-radio label="1">是</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="" prop="validtime"
					v-if="ruleForm.validtime_long == '0' && ruleForm.type < 3">
					<p class="form_label_p">营业执照有效期</p>
					<el-date-picker v-model="ruleForm.validtime" value-format="yyyy-MM-dd" type="date"
						placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<div class="form_img_up" v-if="ruleForm.type < 2">
					<p class="title">企业门头照</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(5)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 5)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="MTfileList">
								<div class="img_up_style">
									<p class="p3">门头照上传</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<div class="form_img_up" v-if="ruleForm.type < 2">
					<p class="title">经营场所照</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(6)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 6)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="JYCSfileList1">
								<div class="img_up_style">
									<p class="p3">经营场所照1</p>
								</div>
							</el-upload>
						</li>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(7)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 7)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="JYCSfileList2">
								<div class="img_up_style">
									<p class="p3">经营场所照2</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<el-form-item label="" prop="license_number" v-if="ruleForm.type < 3">
					<p class="form_label_p">营业执照编码</p>
					<el-input class="input_long" v-model="ruleForm.license_number" placeholder="请输入营业执照编码">
					</el-input>
				</el-form-item>
				<div class="form_img_up" v-if="ruleForm.type < 3">
					<p class="title">开业许可证照片</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(8)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 8)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="KYXKfileList">
								<div class="img_up_style">
									<p class="p3">开业许可照上传</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<el-form-item prop="town" v-if="ruleForm.type < 3">
					<p class="form_label_p">营业执照地址</p>
					<div class="address">
						<el-select v-model="ruleForm.province" filterable placeholder="省" class="miniInput"
							@change="provinceFn">
							<el-option v-for="item in provinceData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="cityShow">
						<el-select v-model="ruleForm.city" filterable placeholder="市" class="miniInput"
							@change="cityFn">
							<el-option v-for="item in cityData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="areaShow">
						<el-select v-model="ruleForm.area" filterable placeholder="区" class="miniInput"
							@change="areaFn">
							<el-option v-for="item in areaData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="townShow">
						<el-select v-model="ruleForm.town" filterable placeholder="镇" class="miniInput">
							<el-option v-for="item in townData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item label="" prop="address" v-if="ruleForm.type < 3">
					<p class="form_label_p">营业执照详细地址</p>
					<el-input class="input_long" v-model="ruleForm.address" placeholder="请输入营业执照详细地址">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="brand_name">
					<p class="form_label_p">开户行</p>
					<el-select class="input_long" v-model="ruleForm.brand_name" filterable placeholder="请选择"
						@change="bankChange">
						<el-option v-for="item in brandName" :key="item.id" :label="item.banktypename"
							:value="item.banktype">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="bank_city">
					<p class="form_label_p">开户行地址</p>
					<div class="address">
						<el-select v-model="ruleForm.bank_province" filterable placeholder="省" class="miniInput"
							@change="bankProvinceFn">
							<el-option v-for="item in provinceData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
					<div class="address" v-if="bankCityShow">
						<el-select v-model="ruleForm.bank_city" filterable placeholder="市" class="miniInput"
							@change="bankChange">
							<el-option v-for="item in bankCityData" :key="item.id" :label="item.name"
								:value="item.id"></el-option>
						</el-select>
					</div>
				</el-form-item>
				<el-form-item label="" prop="bank_name">
					<p class="form_label_p">搜索开户行支行名称</p>
					<el-input placeholder="输入支行名称搜索" v-model="ruleForm.bank_name"
						class="input-with-select input_long_7">
						<el-button slot="append" icon="el-icon-search" @click="backSearch"></el-button>
					</el-input>
				</el-form-item>
				<ul class="back_name" v-if="brandSubBranch.length > 0">
					<li v-for="item in brandSubBranch" :key="item.id" @click="choiceSubBranch(item)">
						{{item.bankname}}
					</li>
				</ul>
				<el-form-item label="" prop="bank_holder">
					<p class="form_label_p">银行卡持卡人</p>
					<el-input class="input_long" v-model="ruleForm.bank_holder" placeholder="请输入银行卡持卡人姓名">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="bank_num">
					<p class="form_label_p">银行卡号</p>
					<el-input class="input_long" v-model="ruleForm.bank_num" placeholder="请输入银行卡卡号">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="bank_mobile">
					<p class="form_label_p">银行卡预留手机号</p>
					<el-input class="input_long" v-model="ruleForm.bank_mobile" maxlength="11"
						placeholder="请输入银行卡预留手机号">
					</el-input>
				</el-form-item>
				<el-form-item label="" prop="bank_id_number">
					<p class="form_label_p">银行卡预留证件号码</p>
					<el-input class="input_long" v-model="ruleForm.bank_id_number" placeholder="请输入银行卡预留证件号">
					</el-input>
				</el-form-item>
				<div class="form_img_up" v-if="ruleForm.type > 1">
					<p class="title">银行卡照片</p>
					<nav>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(9)}" ref="upload" :limit="1"
								:before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 9)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="YHKZfileList">
								<div class="img_up_style">
									<p class="p3">银行卡正面</p>
								</div>
							</el-upload>
						</li>
						<li>
							<el-upload :action="$upImg" :on-remove="() => {onRemove(10)}" ref="upload"
								:limit="1" :before-upload="beforeAvatarUpload"
								:on-success="response => {uploadSuccess(response, 10)}" :data="imageData"
								:show-file-list="true" list-type="picture" :file-list="YHKFfileList">
								<div class="img_up_style">
									<p class="p3">银行卡反面</p>
								</div>
							</el-upload>
						</li>
					</nav>
				</div>
				<el-form-item label="" prop="email">
					<p class="form_label_p">邮箱</p>
					<el-input class="input_long" v-model="ruleForm.email" placeholder="请输入邮箱">
					</el-input>
				</el-form-item>
		
				<div class="el-form-item_last"></div>
				<el-form-item class="el-form-item-preservation">
					<div class="page_edit_preservation">
						<p class="p1" @click="upPage">返回</p>
						<p class="p2" @click="editForm('ruleForm')">保存</p>
					</div>
				</el-form-item>
			</el-form>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'authenticationInfo',
		data(){
			return{
				imageData: {
					'ratio': '110x84,375x240',
					'rootPath': 'gg'
				}, // 上传图片时携带的参数和上传到哪里
				ruleForm: {
					user_type: '2', // 用户类型(默认代理商)
					type: '1', // 认证资质类型
					company: '', // 企业或个体商户名称
					legal_name: '', // 法人/个人姓名
					legal_phone: '', // 法人/个人姓名联系方式
					legal_id_number: '', // 身份证号
					legal_id_valid_long: '0', // 法人身份证是否长期有效
					legal_id_valid: '', // 身份证有效期
					validtime_long: '0', // 营业执照是否长期有效
					validtime: '', // 营业执照有效时间
					license_number: '', // 营业执照编码
					province: '', // 营业执照省
					city: '', // 营业执照市
					area: '', // 营业执照区/县
					town: '', // 营业执照镇
					address: '', // 营业执照详细地址
					brand_name: '', // 开户行名称
					bank_province: '', // 银行所在省
					bank_city: '', // 银行所在市
					bank_name: '', // 支行名称
					bank_holder: '', // 银行卡持卡人
					bank_num: '', // 银行卡号
					bank_mobile: '', // 银行卡预留手机号
					bank_id_number: '', // 银行卡预留证件号
					cust_another_name: '', // 商户简称
					company_province: '', // 商户所在省
					company_city: '', // 商户所在市
					company_area: '', // 商户所在区
					company_address: '', // 商户详细地址
					certifi_effect: '', // 法人证件生效期
					bs_license_effect: '', // 企业证件生效期
					email: '', // 邮箱
				},
				rules: {
					company: [{
						required: true,
						message: '企业或个体商户名称不能为空',
						trigger: 'blur'
					}],
					legal_name: [{
						required: true,
						message: '法人/个人姓名不能为空',
						trigger: 'blur'
					}],
					legal_phone: [{
							required: true,
							message: '法人/个人联系方式不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					legal_id_number: [{
							required: true,
							message: '身份证号不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
							message: '身份证号错误'
						}
					],
					legal_id_valid: [{
						required: true,
						message: '身份证有效期不能为空',
						trigger: 'change'
					}],
					license_number: [{
						required: true,
						message: '营业执照编码不能为空',
						trigger: 'blur'
					}],
					town: [{
						required: true,
						message: '营业执照地址不能为空',
						trigger: 'change'
					}],
					address: [{
						required: true,
						message: '营业执照详细地址不能为空',
						trigger: 'blur'
					}],
					brand_name: [{
						required: true,
						message: '开户银行不能为空',
						trigger: 'change'
					}],
					validtime: [{
						required: true,
						message: '营业执照有效期不能为空',
						trigger: 'change'
					}],
					bank_city: [{
						required: true,
						message: '请选择开户银行所在市',
						trigger: 'change'
					}],
					bank_name: [{
						required: true,
						message: '开户银行支行名称不能为空',
						trigger: 'change'
					}],
					bank_holder: [{
						required: true,
						message: '银行卡持卡人不能为空',
						trigger: 'blur'
					}],
					bank_num: [{
						required: true,
						message: '银行卡号不能为空',
						trigger: 'blur'
					}],
					bank_mobile: [{
							required: true,
							message: '银行卡预留手机号不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					bank_id_number: [{
							required: true,
							message: '银行卡预留证件号不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
							message: '身份证号错误'
						}
					],
					cust_another_name: [{
						required: true,
						message: '商户简称不能为空',
						trigger: 'blur'
					}],
					company_area: [{
						required: true,
						message: '商户所在区/县不能为空',
						trigger: 'change'
					}],
					company_address: [{
						required: true,
						message: '商户详细地址不能为空',
						trigger: 'blur'
					}],
					certifi_effect: [{
						required: true,
						message: '请选择法人证件生效期',
						trigger: 'change'
					}],
					bs_license_effect: [{
						required: true,
						message: '请选择企业证件生效期',
						trigger: 'change'
					}],
					email: [{
							required: true,
							message: '邮箱不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
							message: '邮箱错误'
						}
					],
				},
				SFZImgServe: [], // 身份证正面上传
				SFZfileList: [], // 身份证正面接收
				SFFImgServe: [], // 身份证反面上传
				SFFfileList: [], // 身份证反面接收
				SCImgServe: [], // 手持身份证上传
				SCfileList: [], // 手持身份证接收
				YYZZImgServe: [], // 营业执照上传
				YYZZfileList: [], // 营业执照接收
				MTImgServe: [], // 门头照上传
				MTfileList: [], // 门头照接收
				JYCSImgServe1: [], // 营业场所1上传
				JYCSfileList1: [], // 营业场所1接收
				JYCSImgServe2: [], // 营业场所2上传
				JYCSfileList2: [], // 营业场所2接收
				KYXKImgServe: [], // 开业许可上传
				KYXKfileList: [], // 开业许可接收
				YHKZImgServe: [], // 银行卡正面上传
				YHKZfileList: [], // 银行卡正面接收
				YHKFImgServe: [], // 银行卡反面上传
				YHKFfileList: [], // 银行卡反面接收
				provinceData: [], // 营业执照省
				cityData: [], // 营业执照市
				areaData: [], // 营业执照区/县
				townData: [], // 营业执照镇
				cityShow: false, // 营业执照市的显示
				areaShow: false, // 营业执照区/县的显示
				townShow: false, // 营业执照镇的显示
				companyCityData: [], // 商户所在市
				companyAreaData: [], // 商户所在区/县
				companyCityShow: false, // 商户所在市的显示
				companyAreaShow: false, // 商户所在区/县的显示
				brandName: [], // 银行列表
				bank_type_name: '', // 银行别称
				brandSubBranch: [], // 银行支行列表
				bankCityShow: false, // 银行所在市显示
				bankCityData: [], // 银行所在市
			}
		},
		created() {
			// 获取省的数据调用
			this.acquireProvince();
			
			// 认证信息获取开户行名称
			this.getBackName();
			
			// 获取认证信息
			this.getAuthenticationInfo();
		},
		components:{
			
		},
		methods:{
			// 获取省的数据
			acquireProvince() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/list`,
					data: {},
				}).then(res => {
					if (res.code == '0') {
						this.provinceData = res.data;
					}
				})
			},
			
			// 当省的值发生改变时，获取市
			provinceFn(val) {
				this.cityShow = true;
				this.areaShow = false;
				this.townShow = false;
				this.ruleForm.city = '';
				this.ruleForm.area = '';
				this.ruleForm.town = '';
				let data1 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data1,
				}).then(res => {
					if (res.code == '0') {
						this.cityData = res.data;
						this.townData = [];
					}
				})
			},
			
			// 当市的值发生改变时获取区/县
			cityFn(val) {
				this.areaShow = true;
				this.townShow = false;
				this.ruleForm.area = '';
				this.ruleForm.town = '';
				let data2 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data2,
				}).then(res => {
					if (res.code == '0') {
						this.areaData = res.data;
					}
				})
			},
			
			// 当区县的值发生改变时获取镇
			areaFn(val) {
				this.townShow = true;
				this.ruleForm.town = '';
				let data3 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data3,
				}).then(res => {
					if (res.code == '0') {
						this.townData = res.data;
					}
				})
			},
			
			// 认证信息银行所在省发生改变
			bankProvinceFn(val) {
				this.bankCityShow = true;
				this.ruleForm.bank_city = '';
				let data1 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data1,
				}).then(res => {
					if (res.code == '0') {
						this.bankCityData = res.data;
					}
				})
			},
			
			// 认证信息银行支行搜索
			backSearch() {
				if (!this.ruleForm.brand_name) {
					this.$message({
						showClose: true,
						message: '请选择开户行名称',
						type: 'error'
					});
					return
				} else {
					this.brandName.forEach(item => {
						if (item.banktype == this.ruleForm.brand_name) {
							this.bank_type_name = item.banktypename;
						}
					})
				}
			
				let cityStr = '';
				if (!this.ruleForm.bank_city) {
					this.$message({
						showClose: true,
						message: '请选择开户行所在省市地址',
						type: 'error'
					});
					return
				} else {
					this.bankCityData.forEach(item => {
						if (item.id == this.ruleForm.bank_city) {
							cityStr = item.short_name;
						}
					})
				}
			
				if (!this.ruleForm.bank_name) {
					this.$message({
						showClose: true,
						message: '请输入支行关键字搜索',
						type: 'error'
					});
					return
				}
			
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}ddpay/banklist`,
					data: {
						bankTypeName: this.bank_type_name,
						city: cityStr,
						keyWord: this.ruleForm.bank_name,
					}
				}).then(res => {
					if (res.code == '0') {
						this.brandSubBranch = res.data;
					} else {
						this.brandSubBranch = [];
						this.$message.error('未搜索到符合的支行，建议多关键字搜索');
					}
				})
			},
			
			// 认证信息选中支行
			choiceSubBranch(item) {
				this.ruleForm.bank_name = item.bankname;
				this.brandSubBranch = [];
			},
			
			// 删除文件时的钩子函数
			onRemove(index) {
				if (index == 1) {
					this.SFZImgServe = [];
					this.SFZfileList = [];
				} else if (index == 2) {
					this.SFFImgServe = [];
					this.SFFfileList = [];
				} else if (index == 3) {
					this.SCImgServe = [];
					this.SCfileList = [];
				} else if (index == 4) {
					this.YYZZImgServe = [];
					this.YYZZfileList = [];
				} else if (index == 5) {
					this.MTImgServe = [];
					this.MTfileList = [];
				} else if (index == 6) {
					this.JYCSImgServe1 = [];
					this.JYCSfileList1 = [];
				} else if (index == 7) {
					this.JYCSImgServe2 = [];
					this.JYCSfileList2 = [];
				} else if (index == 8) {
					this.KYXKImgServe = [];
					this.KYXKfileList = [];
				} else if (index == 9) {
					this.YHKZImgServe = [];
					this.YHKZfileList = [];
				} else if (index == 10) {
					this.YHKFImgServe = [];
					this.YHKFfileList = [];
				}
			},
			
			// 上传前的钩子函数
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message({
						message: '上传图片大小不能超过 10MB!',
						type: 'warning',
			
					});
					return;
				}
				return isLt2M
			},
			
			// 图片上传成功
			uploadSuccess(response, index) {
				if (index == 1) {
					this.SFZImgServe.push(response)
				} else if (index == 2) {
					this.SFFImgServe.push(response)
				} else if (index == 3) {
					this.SCImgServe.push(response)
				} else if (index == 4) {
					this.YYZZImgServe.push(response)
				} else if (index == 5) {
					this.MTImgServe.push(response)
				} else if (index == 6) {
					this.JYCSImgServe1.push(response)
				} else if (index == 7) {
					this.JYCSImgServe2.push(response)
				} else if (index == 8) {
					this.KYXKImgServe.push(response)
				} else if (index == 9) {
					this.YHKZImgServe.push(response)
				} else if (index == 10) {
					this.YHKFImgServe.push(response)
				}
			
			},
			
			// 认证信息获取开户行名称
			getBackName() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}ddpay/banktypename`,
					data: {}
				}).then(res => {
					if (res.code == '0') {
						// 因为检索不能循环数字，所以把id转成字符串
						res.data.forEach(item => {
							item.id = item.id.toString()
						})
			
						this.brandName = res.data;
					}
				})
			},
			
			// 获取认证信息
			getAuthenticationInfo() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agent/detailcmaf`,
					data: {
						user_id: this.$route.query.id,
						user_type: this.ruleForm.user_type,
					}
				}).then(res => {
					if (res.code == '0') {
						this.ruleForm.type = res.data.type;
						this.ruleForm.company = res.data.company;
						this.ruleForm.cust_another_name = res.data.cust_another_name;
						this.ruleForm.company_address = res.data.company_address;
						this.ruleForm.company = res.data.company;
						this.ruleForm.company_province = res.data.company_province;
						this.ruleForm.company_city = res.data.company_city;
						this.ruleForm.bank_province = res.data.bank_province;
						this.ruleForm.province = res.data.province_id;
						this.ruleForm.address = res.data.address;
						this.ruleForm.certifi_effect = res.data.certifi_effect;
						this.ruleForm.bs_license_effect = res.data.bs_license_effect;
						this.ruleForm.bank_holder = res.data.bank_holder;
						this.ruleForm.legal_name = res.data.legal_name;
						this.ruleForm.legal_phone = res.data.legal_phone;
						this.ruleForm.license_number = res.data.license_number;
						this.ruleForm.legal_id_number = res.data.legal_id_number;
						this.ruleForm.validtime_long = res.data.validtime_long;
						this.ruleForm.bank_name = res.data.bank_name;
						this.ruleForm.bank_mobile = res.data.bank_mobile;
						this.ruleForm.bank_id_number = res.data.bank_id_number;
						this.ruleForm.bank_num = res.data.bank_num;
						this.ruleForm.brand_name = res.data.bank_type;
						this.ruleForm.legal_id_valid = res.data.legal_id_valid;
						this.ruleForm.validtime = res.data.validtime;
						this.ruleForm.email = res.data.email;
						
						if(res.data.legal_id_valid_long){
							this.ruleForm.legal_id_valid_long = res.data.legal_id_valid_long.toString();
						}
						
						if (res.data.id_head_image) {
							this.SFZImgServe.push(res.data.id_head_image);
							this.SFZfileList.push({
								url: res.data.id_head_image
							})
						}
						if (res.data.id_emblem_image) {
							this.SFFImgServe.push(res.data.id_emblem_image);
							this.SFFfileList.push({
								url: res.data.id_emblem_image
							});
						}
						if (res.data.hand_id_image) {
							this.SCImgServe.push(res.data.hand_id_image);
							this.SCfileList.push({
								url: res.data.hand_id_image
							});
						}
						if (res.data.license_img) {
							this.YYZZImgServe.push(res.data.license_img);
							this.YYZZfileList.push({
								url: res.data.license_img
							});
						}
						if (res.data.facade_image) {
							this.MTImgServe.push(res.data.facade_image);
							this.MTfileList.push({
								url: res.data.facade_image
							});
						}
						if (res.data.trade_one_image) {
							this.JYCSImgServe1.push(res.data.trade_one_image);
							this.JYCSfileList1.push({
								url: res.data.trade_one_image
							});
						}
						if (res.data.trade_two_image) {
							this.JYCSImgServe2.push(res.data.trade_two_image);
							this.JYCSfileList2.push({
								url: res.data.trade_two_image
							});
						}
						if (res.data.account_license_code) {
							this.KYXKImgServe.push(res.data.account_license_code);
							this.KYXKfileList.push({
								url: res.data.account_license_code
							});
						}
						if (res.data.bank_head_image) {
							this.YHKZImgServe.push(res.data.bank_head_image);
							this.YHKZfileList.push({
								url: res.data.bank_head_image
							});
						}
						if (res.data.bank_emblem_image) {
							this.YHKFImgServe.push(res.data.bank_emblem_image);
							this.YHKFfileList.push({
								url: res.data.bank_emblem_image
							});
						}
			
			
						if (this.ruleForm.province) {
							this.provinceFn(this.ruleForm.province);
							this.ruleForm.city = res.data.city_id;
						}
						if (this.ruleForm.city) {
							this.cityFn(this.ruleForm.city)
							this.ruleForm.area = res.data.district_id;
						}
						if (this.ruleForm.area) {
							this.areaFn(this.ruleForm.area)
							this.ruleForm.town = res.data.town_id;
						}
			
						if (this.ruleForm.company_province) {
							this.companyProvinceFn(this.ruleForm.company_province);
							this.ruleForm.company_city = res.data.company_city;
						}
						if (this.ruleForm.company_city) {
							this.companyCityFn(this.ruleForm.company_city)
							this.ruleForm.company_area = res.data.company_area;
						}
			
						if (this.ruleForm.bank_province) {
							this.bankProvinceFn(this.ruleForm.bank_province);
							this.ruleForm.bank_city = res.data.bank_city;
						}
			
					}
				})
			},
			
			// 点击页面把支行名称列表隐藏
			clickPageBankOn() {
				this.brandSubBranch = [];
			},
			
			// 当银行名称和所在市发生改变时把支行清空
			bankChange() {
				this.ruleForm.bank_name = '';
				this.brandSubBranch = [];
			},
			
			// 认证信息商户所在地址点击省获取市
			companyProvinceFn(val) {
				this.ruleForm.company_city = '';
				this.ruleForm.company_area = '';
				this.companyCityShow = true;
				this.companyAreaShow = false;
				let data1 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data1,
				}).then(res => {
					if (res.code == '0') {
						this.companyCityData = res.data;
						this.companyAreaData = [];
					}
				})
			},
			
			// 认证信息商户所在地址点击市获取区/县
			companyCityFn(val) {
				this.ruleForm.company_area = '';
				this.companyAreaShow = true;
				let data2 = {
					"id": val,
				}
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}regions/getson`,
					data: data2,
				}).then(res => {
					if (res.code == '0') {
						this.companyAreaData = res.data;
					}
				})
			},
			
			// 认证信息编辑
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
			
						let img1 = '';
						if (this.SFZImgServe.length == 0) {
							this.$alert('请上传身份证正面照', '照片缺失', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return;
						} else {
							if (typeof this.SFZImgServe[0] == 'string') {
								img1 = this.SFZImgServe[0];
							} else {
								img1 = JSON.stringify(this.SFZImgServe);
							}
						}
						
						let img2 = '';
						if (this.SFFImgServe.length == 0) {
							this.$alert('请上传身份证反面照', '照片缺失', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return;
						}
						else{
							if (typeof this.SFFImgServe[0] == 'string') {
								img2 = this.SFFImgServe[0];
							} else {
								img2 = JSON.stringify(this.SFFImgServe);
							}
						}
						
						let img3 = '';
						if (this.SCImgServe.length == 0) {
							this.$alert('请上传手持身份证照片', '照片缺失', {
								confirmButtonText: '确定',
								callback: () => {}
							});
							return;
						}
						else{
							if (typeof this.SCImgServe[0] == 'string') {
								img3 = this.SCImgServe[0];
							} else {
								img3 = JSON.stringify(this.SCImgServe);
							}
						}
						
						let img4 = '';
						let img5 = '';
						let img6 = '';
						if (this.ruleForm.type == 1) {
							if (this.MTImgServe.length == 0) {
								this.$alert('请上传企业门头照', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.MTImgServe[0] == 'string') {
									img4 = this.MTImgServe[0];
								} else {
									img4 = JSON.stringify(this.MTImgServe);
								}
							}
							if (this.JYCSImgServe1.length == 0) {
								this.$alert('请上企业经营场所照片1', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.JYCSImgServe1[0] == 'string') {
									img5 = this.JYCSImgServe1[0];
								} else {
									img5 = JSON.stringify(this.JYCSImgServe1);
								}
							}
							if (this.JYCSImgServe2.length == 0) {
								this.$alert('请上企业经营场所照片2', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.JYCSImgServe2[0] == 'string') {
									img6 = this.JYCSImgServe2[0];
								} else {
									img6 = JSON.stringify(this.JYCSImgServe2);
								}
							}
						}
						
						let img7 = '';
						let img8 = '';
						if (this.ruleForm.type < 3) {
							if (this.YYZZImgServe.length == 0) {
								this.$alert('请上传营业执照照片', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.YYZZImgServe[0] == 'string') {
									img7 = this.YYZZImgServe[0];
								} else {
									img7 = JSON.stringify(this.YYZZImgServe);
								}
							}
							
							if (this.KYXKImgServe.length == 0) {
								this.$alert('请上传开业许可证照片', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.KYXKImgServe[0] == 'string') {
									img8 = this.KYXKImgServe[0];
								} else {
									img8 = JSON.stringify(this.KYXKImgServe);
								}
							}
						}
						
						let img9 = '';
						let img10 = '';
						if (this.ruleForm.type > 1) {
							if (this.YHKZImgServe.length == 0) {
								this.$alert('请上传银行卡正面照片', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.YHKZImgServe[0] == 'string') {
									img9 = this.YHKZImgServe[0];
								} else {
									img9 = JSON.stringify(this.YHKZImgServe);
								}
							}
							
							if (this.YHKFImgServe.length == 0) {
								this.$alert('请上传银行卡反面照片', '照片缺失', {
									confirmButtonText: '确定',
									callback: () => {}
								});
								return;
							}
							else{
								if (typeof this.YHKFImgServe[0] == 'string') {
									img10 = this.YHKFImgServe[0];
								} else {
									img10 = JSON.stringify(this.YHKFImgServe);
								}
							}
						}
			
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}agent/editcmaf`,
							data: {
								user_id: this.$route.query.id,
								user_type: this.ruleForm.user_type,
								type: this.ruleForm.type,
								company: this.ruleForm.company,
								legal_name: this.ruleForm.legal_name,
								legal_phone: this.ruleForm.legal_phone,
								legal_id_number: this.ruleForm.legal_id_number,
								legal_id_valid: this.ruleForm.legal_id_valid,
								validtime_long: this.ruleForm.validtime_long,
								validtime: this.ruleForm.validtime,
								license_number: this.ruleForm.license_number,
								province_id: this.ruleForm.province,
								city_id: this.ruleForm.city,
								district_id: this.ruleForm.area,
								town_id: this.ruleForm.town,
								address: this.ruleForm.address,
								bank_holder: this.ruleForm.bank_holder,
								bank_num: this.ruleForm.bank_num,
								bank_type: this.ruleForm.brand_name,
								bank_province: this.ruleForm.bank_province,
								bank_city: this.ruleForm.bank_city,
								bank_name: this.ruleForm.bank_name,
								bank_mobile: this.ruleForm.bank_mobile,
								bank_id_number: this.ruleForm.bank_id_number,
								bank_type_name: this.bank_type_name,
								company_province: this.ruleForm.company_province,
								company_city: this.ruleForm.company_city,
								company_area: this.ruleForm.company_area,
								company_address: this.ruleForm.company_address,
								certifi_effect: this.ruleForm.certifi_effect,
								bs_license_effect: this.ruleForm.bs_license_effect,
								cust_another_name: this.ruleForm.cust_another_name,
								legal_id_valid_long: this.ruleForm.legal_id_valid_long,
								email: this.ruleForm.email,
								id_head_image: img1,
								id_emblem_image: img2,
								hand_id_image: img3,
								facade_image: img4,
								trade_one_image: img5,
								trade_two_image: img6,
								license_img: img7,
								account_license_code: img8,
								bank_head_image: img9,
								bank_emblem_image: img10,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$alert('编辑成功', '认证信息编辑', {
									confirmButtonText: '确定',
									callback: () => {
										this.$message({
											message: '编辑成功',
											type: 'success'
										});
										this.$router.go(-1);
									}
								});
			
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
			
			
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},
		}
	}
</script>

<style scoped lang="less">
	.input_long_7 {
		width: 590px;
	}
	
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}
	
	/* 长input */
	.input_long {
		width: 400px;
	}
	
	/* 中等input */
	.input_medi {
		width: 200px;
	}
	
	/* mini input */
	.input_mini {
		width: 100px;
	}
	
	.el-tab-wrap {
		margin: 16px 0;
	}
	
	// 认证信息图片上传
	.form_img_up {
		padding: 24px;
		background-color: #fff;
	
		.title {
			font-size: 14px;
			color: rgba(0, 0, 0, .85);
			height: 44px;
			line-height: 44px;
		}
	
		nav {
			display: flex;
			justify-content: flex-start;
	
			li {
				width: 134px;
				height: 134px;
				border: 1px dashed rgba(0, 0, 0, 0.2);
				background-color: rgba(0, 0, 0, .02);
				margin-right: 30px;
				position: relative;
	
				.img_up_style {
					width: 134px;
					height: 134px;
	
					.p1 {
						font-size: 14px;
						color: rgba(0, 0, 0, .45);
						margin-top: 36px;
					}
	
					.p2 {
						width: 90px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border-radius: 2px;
						border: 1px solid rgba(0, 0, 0, .15);
						margin: 16px auto 0;
					}
	
					.p3 {
						line-height: 134px;
						font-size: 14px;
						color: rgba(0, 0, 0, .65);
					}
				}
	
			}
		}
	}
	
	
	// 认证信息营业执照地址
	.address {
		display: inline-block;
		vertical-align: top;
		width: 300px;
	
		.miniInput {
			width: 290px;
		}
	}
	
	// 银行卡搜索返回
	.back_name {
		width: 590px;
		max-height: 400px;
		border: 1px solid rgba(0, 0, 0, .15);
		margin: -19px 0 0 24px;
		overflow: hidden;
		overflow: auto;
	
		li {
			height: 40px;
			line-height: 44px;
			font-size: 14px;
			color: rgba(0, 0, 0, .85);
			text-indent: 12px;
			border-bottom: 1px dashed rgba(0, 0, 0, .15);
			cursor: pointer;
		}
	
		li:hover {
			background-color: rgba(0, 0, 0, .02);
			color: #EE7B1D;
		}
	}
</style>
